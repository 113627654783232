import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/chrome-devtools-web-app-developers",
  "date": "2013-09-24",
  "title": "CHROME DEVTOOLS FOR WEB APP DEVELOPERS",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "The Chrome DevTools (Developer Tools), are a set web authoring and debugging tools built into Google Chrome. The DevTools provide web developers deep access into the internals of the browser and their web application. "
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This overview of DevTools points out the most popular and useful features. DevTools help inspect, debug and optimize Web applications. Following are a break down of features DevTools provide:Inspecting the DOM and styles`}</p>
    <p>{`Inspecting the DOM and styles`}</p>
    <ul>
      <li parentName="ul">{`Working with the console`}</li>
      <li parentName="ul">{`Debugging JavaScript`}</li>
      <li parentName="ul">{`Improving network performance`}</li>
      <li parentName="ul">{`Improving rendering performance`}</li>
      <li parentName="ul">{`Improving JavaScript `}{`&`}{` CSS performance`}</li>
      <li parentName="ul">{`Inspecting Storage
`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/2013-09-24-chrome-devtools-web-app-developers/devtools.png",
        "alt": "DevTools"
      }}></img>{`   `}</p>
    <h2>{`Web Developer Toolbar`}</h2>
    <p>{`Chrome store location: `}<a title="Web Developer Toobar" href="https://chrome.google.com/webstore/detail/web-developer/bfbameneiokkgbdmiekhjnmfkcnldhhm?hl=en" target="_blank">{`Web Developer Toolbar`}</a></p>
    <p>{`Adds a toolbar button with various web developer tools. The Web Developer extension adds a toolbar button to the browser with various web developer tools. This is the official port of the Web Developer extension for Firefox. The extension is available for `}<a href="http://www.google.com/chrome/">{`Chrome`}</a>{` and `}<a href="http://www.mozilla.com/firefox/">{`Firefox`}</a>{`, and will run on any platform that these browsers support including Windows, OS X and Linux.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-09-24-chrome-devtools-web-app-developers/web-developer.png",
        "alt": "Web Developer"
      }}></img>{`   `}</p>
    <h2>{`Backbone DevTools`}</h2>
    <p>{`Chrome store location: `}<a title="Backbone DevTools" href="https://chrome.google.com/webstore/detail/backbone-debugger/bhljhndlimiafopmmhjlgfpnnchjjbhd?hl=en" target="_blank">{`Backbone DevTools`}</a>{`
Backbone team hasn't done much for improving the tool itself but this is good enough for debugging Backbone.js apps. Following are the features provided by Backbone DevTools:`}</p>
    <ul>
      <li parentName="ul">{`Inspects views, models, collections and routers`}</li>
      <li parentName="ul">{`Detecst which objects have been instantiated`}</li>
      <li parentName="ul">{`Diaplys a very useful `}<strong parentName="li">{`view`}</strong>{`" hierarchy in the Elements panel`}</li>
      <li parentName="ul">{`Inspect a DOM element and the closest View will be exposed via $view in the console."`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/2013-09-24-chrome-devtools-web-app-developers/backbone.png",
        "alt": "Backbone"
      }}></img>{`  `}</p>
    <h2>{`Ember Inspector`}</h2>
    <p>{`Chrome store location: `}<a title="Ember Inspector" href="https://chrome.google.com/webstore/detail/ember-inspector/bmdblncegkenkacieihfhpjfppoconhi?hl=en" target="_blank">{`Ember Inspector`}</a>{` Yehuda Katz has been working on Ember Inspector, which improves Ember.js debugging workflow with easy inspection of controllers, views, and objects  when running an Ember.js application. This tools provides features to get insights into models and their attributes, layers, bound computed properties and avoid the need to dig deep into the Ember object graph to figure out what is happening behind the scene." `}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-09-24-chrome-devtools-web-app-developers/ember.png",
        "alt": "Ember"
      }}></img></p>
    <h2>{`Angular Batarang`}</h2>
    <p>{`Chrome store location: `}<a title="Angular Batarang" href="https://chrome.google.com/webstore/detail/angularjs-batarang/ighdmehidhipcmcojjgiloacoafjmpfk?hl=en" target="_blank">{`Angular Batarang`}</a>{` Angular Batarang(a superheroic tool that lives up to its name)  is the most mature of the extensions you can find." 
The root scope(Scopes provide mechanism for watching the model and provide event processing life cycle) can get diffcult to trace ,so it's useful that it helps you to inspect nested scopes. `}</p>
    <p>{`Angular Batarang provides following features:`}</p>
    <ul>
      <li parentName="ul">{`Visualise dependancies`}</li>
      <li parentName="ul">{`Debug applications`}</li>
      <li parentName="ul">{`Analyse performance of app extensions to address perfomance bottlenecks`}</li>
      <li parentName="ul">{`Get insights into models`}</li>
      <li parentName="ul">{`View DOM elements of a scope`}</li>
      <li parentName="ul">{`Inspect nested scopes`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/2013-09-24-chrome-devtools-web-app-developers/angular_batarang.png",
        "alt": "Angular Batarang"
      }}></img>{`  `}</p>
    <h2>{`Thorax Inspector`}</h2>
    <p>{`Chrome store location:  `}<a title="Thorax Inspector" href="https://chrome.google.com/webstore/detail/thorax-inspector/poioalbefcopgeaeaadelomciijaondk?hl=en" target="_blank">{`Thorax Inspector`}</a>{`
Thorax Inspector is an inspector extension for Thorax, a Backbone + Handlebars framework to build large scale web applications.`}</p>
    <p>{`This tool lets you inspect Thorax projects, Views, Models and Collections via the Elements panel`}<strong parentName="p">{`.`}</strong>{`"`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-09-24-chrome-devtools-web-app-developers/thorax.png",
        "alt": "Thorax"
      }}></img>{`  `}</p>
    <h2>{`KnockoutJS ContextDebugger`}</h2>
    <p>{`Chrome store location: `}<a title="knockout Context Debugger" href="https://chrome.google.com/webstore/search/knockout%20context?hl=en" target="_blank">{`Knockout Context Debugger`}</a></p>
    <p>{`Knockoutjs Context Debugger shows the knockout context `}{`&`}{` data for the selected DOM node in a sidebar of the elements pane. Knockoutjs debugging extension adds a extra sidebar in the elements pane of the chrome dev tools with the relevant knockout context and data for the selected element. Very usefull if you got large/nested viewmodels. Allows to add tracing to every observable/computed.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-09-24-chrome-devtools-web-app-developers/knckout-debuger.png",
        "alt": "Knockout"
      }}></img>{`  `}</p>
    <h2>{`Rails Panel`}</h2>
    <p>{`Chrome store location: `}<a title="Rails Panel" href="https://chrome.google.com/webstore/detail/railspanel/gjpfobpafnhjhbajcjgccbbdofdckggg" target="_blank">{`Rails Panel`}</a>{` RailsPanel is a Chrome extension for Rails development that will end your tailing of `}<i>{`development.log`}</i>{`. Have all information about your Rails app requests right there in the Developer Tools panel. Provides insight to db/rendering/total times, parameter list, rendered views, text editor integration and more. To use this extension you need to add meta_request gem to your app's Gemfile: group :development do gem 'meta_request' end
Tincr Chrome store location: `}<a title="Tincr" href="https://chrome.google.com/webstore/detail/tincr/lfjbhpnjiajjgnjganiaggebdhhpnbih" target="_blank">{`Tincr`}</a>{` Extension to allow editing and fast reloading of local files from Chrome developer tools The idea behind Tincr is that you can save changes to your original source file from within Chrome Developer Tools. In addition, Tincr does auto-reloading of JavaScript and CSS changes made in other editors.
CSS-Shack Chrome store location: `}<a title="CSS-Shack" href="https://chrome.google.com/webstore/detail/css-shack/geiccgjkigajaicecnhdokggninehdlp" target="_blank">{`CSS-Shack`}</a>{` CSS-Shack allows you to create Layers Styles (just like you would in any other image editing software), and export them into a single CSS file, or copy them to the clipboard. You can create website designs, mockups or just simple button. Notable features :`}</p>
    <ul>
      <li parentName="ul">{`Fully CSS3 compatible`}</li>
      <li parentName="ul">{`Images support`}</li>
      <li parentName="ul">{`11 filters`}</li>
      <li parentName="ul">{`Works offline`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/2013-09-24-chrome-devtools-web-app-developers/css-shack.png",
        "alt": "CSS Shack"
      }}></img></p>
    <h2>{`Others`}</h2>
    <p>{`There are more extensions and helpers out there. Following are few of them:`}</p>
    <ul>
      <li parentName="ul"><a title="PrettyPrint" href="https://chrome.google.com/webstore/detail/prettyprint/nipdlgebaanapcphbcidpmmmkcecpkhg">PrettyPrint </a>- Unminify JavaScript and CSS automatically when using view-source</li>
      <li parentName="ul"><a title="JS Runtime Inspector" href="https://chrome.google.com/webstore/detail/js-runtime-inspector/iilpjebedgohcmlffhnkhbjhabkdhfmn" target="_blank">JS Runtime Inspector</a> - For easily grepping JavaScript objects from the Elements panel</li>
      <li parentName="ul"><a title="JetBrains IDE Support" href="https://chrome.google.com/webstore/detail/jetbrains-ide-support/hmhgeddbohgjknpmjagkdomcpobmllji" target="_blank">JetBrains IDE Support</a> -  Live editing  and see real-time changes in Chrome when used with WebStormIDE</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      